import React from "react";
import Header from "./Header";
import TwitterIcon from "../../assets/img/tw.svg";
import LinkIcon from "../../assets/img/link.svg";
import FbIcon from "../../assets/img/fb.svg";

const Footer = () => {
    return(<div className="footerpayment">
        <div className="col-6">
            <div className="paymentdetailfoot">
                <Header logotitle={"Nationwide Software Solution"} />
                <p>Nationwide payment portal. Customer can pay the invoice throught credit card, debit cart etc.</p>
                <div className="paymentdetlink">
                    <ul>
                        <li><a href="javscript:void(0);">Help</a></li>
                        <li><a href="javscript:void(0);">Privacy</a></li>
                        <li><a href="javscript:void(0);">Terms Condition</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="col-6">
            <div className="paymentdetailfootcontact">
                <div className="contctcols">
                    <h5>Contact Us</h5>
                    <h2><a href="tel: +1 788 - 878 - 8788">+1 788 - 878 - 8788</a></h2>
                </div>
                <div className="contctcols">
                    <h5>Email Id</h5>
                    <h2><a href="mailto: support@nationwidesoftwaresolution.com">support@nationwidesoftwaresolution.com</a></h2>
                </div>
            </div>
        </div>
        <div className="col-12 footercopyright">
            <p>© 2023 Nationwide software solution. All rights reserved.</p>
            <ul className="sociallinks">
                <li>
                    <a href="javscript:void(0);"><img src={TwitterIcon} alt="twitter" /></a>
                </li>
                <li>
                    <a href="javscript:void(0);"><img src={LinkIcon} alt="twitter" /></a>
                </li>
                <li>
                    <a href="javscript:void(0);"><img src={FbIcon} alt="twitter" /></a>
                </li>
            </ul>
        </div>
    </div>);
}

export default Footer;