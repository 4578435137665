/* eslint-disable */
import React, { useState } from "react";
import { Country, State, City }  from 'country-state-city';
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import SucessIcon from "../../assets/img/success.svg";
import CustomMendotoryMsg from "../Common/CustomMendotoryMsg";
import { cardDetailsGet } from "../../redux/actions";
import { useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const PaymentCardDetails = () => {
    const Navigate = useNavigate();
    const dispatch = useDispatch();
    const [ creditCard, setCreditCard ] = useState("");
    const [ expiryDate, setExpiryDate ] = useState("");
    const [ cvv, setCVV ] = useState("");
    const [ cardholderName, setCardholderName ] = useState("");
    const [ PayAmount, setPayAmount ] = useState("");
    const [ SelectCountry, setSelectCountry ] = useState("");
    const [ BillingAddress, setBillingAddress ] = useState("");
    const [ SelectStates, setSelectStates ] = useState({
        Countrycode: "",
        Statecode: "",
    });
    const [ SelectCity, setSelectCity ] = useState("");
    const [ apt_ste_bldg , setApt_ste_bldg ] = useState("");
    const [ Zip, setZip ] = useState("");

    // mendotory error state
    const [CustomError, setCustomError] = useState(false);

    // select credit card
    const creditcardNo = (e) => {
        setCreditCard(e.target.value.replace(/[^0-9]/g, "").replace(/\W/gi, '').replace(/(.{4})/g, '$1 '))
    }
    
    // select country 
    const SelectCoutry = (event) => {
        setSelectCountry(event.target.value);
    };

    // select state
    const SelectState = (event) => {
        var CodeCountries = event.target.value.split("/");
        setSelectStates({
            ...SelectStates,
            Countrycode: CodeCountries[0],
            Statecode: CodeCountries[1],
        });
    }

    // select city
    const SelectCityFnct = (event) => {
        var CodeCountries = event.target.value;
        setSelectCity(CodeCountries);
    }

    // form submit
    const submitForm = () => {
        if(creditCard !== "" && expiryDate !== "" && cvv !== "" && SelectStates.Countrycode !== "" && cardholderName !== "" && PayAmount !== "" && SelectCountry !== "" && BillingAddress !== "") {
            setCustomError(false);
            const payload = {
                CreditCardNumber: creditCard,
                ExpiryDate: expiryDate,
                CVV: cvv,
                CardholderName: cardholderName,
                PayAmount: PayAmount,
                Country: SelectCountry,
                BillingAddress: BillingAddress,
                State: SelectStates.Statecode,
                City: SelectCity,
                Aptstebldg: apt_ste_bldg,
                Zip: Zip
            };
            setTimeout(()=> {
                dispatch(cardDetailsGet(payload));
                Navigate("/confirm-payment");
            }, 100);
        } else {
            setCustomError(true);
        };
    }

    

    return(<div className="paymentWrappers">
            <div className="paymentheadermain">
                <h4>Payment Card Details :</h4>
            </div>
            <div className="paymentforms">
                <div className="rowcustom">
                    <div className="col-md-12">
                        <div className={CustomError && creditCard === "" ? "form-group error" : "form-group"}>
                            <label>Credit Card Number</label>
                            <div className="form-groupfiled">
                                <input type="tel" className="form-control" value={creditCard} placeholder="Enter Credit Card Number" onChange={(e) => creditcardNo(e)} maxLength="19" pattern="\d*" />
                                {creditCard !== "" && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                                {CustomError && creditCard === "" && <CustomMendotoryMsg value={creditCard} label={"Credit Card Number"} />}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rowcustom rowcustom-col-2">
                    <div className={CustomError && (expiryDate === "" || expiryDate === null) ? "form-group error" : "form-group"}>
                        <label>Expiry Date</label>
                        <div className="form-groupfiled expirydatswrapper">
                            <DatePicker
                                selected={expiryDate}
                                onChange={(date) => setExpiryDate(date)}
                                dateFormat="MM/yyyy"
                                placeholderText="Select Expiry Date"
                                showMonthYearPicker
                            />
                            {expiryDate !== "" && expiryDate !== null && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                            {CustomError && (expiryDate === "" || expiryDate === null) && <CustomMendotoryMsg value={creditCard} label={"Expiry date"} />}
                        </div>
                    </div>
                    <div className={CustomError && cvv === "" ? "form-group error" : "form-group"}>
                        <label>CVV</label>
                        <div className="form-groupfiled">
                            <input type="text" value={cvv} onChange={(e) => setCVV(e.target.value)} className="form-control" placeholder="Enter CVV" maxLength="3" />
                            {cvv !== "" && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                            {CustomError && cvv === "" && <CustomMendotoryMsg value={cvv} label={"CVV"} />}
                        </div>
                    </div>
                </div>
                <div className="rowcustom">
                    <div className="col-md-12">
                        <div className={CustomError && cardholderName === "" ? "form-group error" : "form-group"}>
                            <label>Cardholder Name</label>
                            <div className="form-groupfiled">
                                <input type="text" className="form-control" value={cardholderName} onChange={(e) => setCardholderName(e.target.value)} placeholder="Enter Cardholder Name" />
                                {cardholderName !== "" && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                                {CustomError && cardholderName === "" && <CustomMendotoryMsg value={cardholderName} label={"Cardholder Name"} />}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rowcustom">
                    <div className="col-md-12">
                        <div className={CustomError && PayAmount === "" ? "form-group error" : "form-group"}>
                            <label>Pay Amount</label>
                            <div className="form-groupfiled">
                                <div className="paymentTextfiled">
                                    <span className="dollartextfiled">$</span>
                                    <input type="number" className="form-control" value={PayAmount} onChange={(e) => setPayAmount(e.target.value)} placeholder="Enter Pay Amount" />
                                </div>
                                {PayAmount !== "" && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                                {CustomError && PayAmount === "" && <CustomMendotoryMsg value={PayAmount} label={"Pay Amount"} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="paymentheadermain">
                <h4>Billing Details :</h4>
            </div>
            <div className="paymentforms">
                <div className="rowcustom">
                    <div className="col-md-12">
                        <div className={CustomError && SelectCountry === "" ? "form-group error" : "form-group"}>
                            <label>Country</label>
                            <div className="form-groupfiled">
                                <select className="form-control" onChange={(e) => SelectCoutry(e)} defaultValue="Select Country" aria-label="Default select example">
                                    <option value="">Select Country</option>
                                    {Country.getAllCountries().map((elm, index) => {
                                        if(elm.name === "United States") {
                                            return(<option value={elm.isoCode} key={index}>{elm.name}</option>)
                                        }
                                    })}
                                </select>
                                {CustomError && SelectCountry === "" && <CustomMendotoryMsg value={SelectCountry} label={"Country"} />}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rowcustom">
                    <div className="col-md-12">
                        <div className={CustomError && BillingAddress === "" ? "form-group error" : "form-group"}>
                            <label>Billing Address</label>
                            <div className="form-groupfiled">
                                <input type="text" className="form-control" value={BillingAddress} onChange={(e) => setBillingAddress(e.target.value)} placeholder="Enter Billing Address" />
                                {CustomError && BillingAddress === "" && <CustomMendotoryMsg value={BillingAddress} label={"Billing Address"} />}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rowcustom rowcustom-col-2" >
                    <div className={CustomError && SelectStates.Countrycode === "" ? "form-group error" : "form-group"}>
                        <label>State</label>
                        <div className="form-groupfiled">
                            <select className="form-control" onChange={(e) => SelectState(e)} defaultValue="Select State" aria-label="Default select example">
                                <option value="">Select State</option>
                                {State.getStatesOfCountry(SelectCountry).map((elm, index) => {
                                    return(<option value={elm.countryCode + "/" +elm.isoCode} key={index}>{elm.name}</option>)
                                })}
                            </select>   
                            {CustomError && SelectStates.Countrycode === "" && <CustomMendotoryMsg value={SelectStates.Countrycode} label={"State"} />}
                        </div>
                    </div>
                    <div className={CustomError && SelectCity === "" ? "form-group error" : "form-group"}>
                        <label>City</label>
                        <div className="form-groupfiled">
                            <select className="form-control" onChange={(e) => SelectCityFnct(e)} defaultValue="Select State" aria-label="Default select example">
                                <option value="">Select City</option>
                                {City.getCitiesOfState(SelectStates.Countrycode, SelectStates.Statecode).map((elm, index) => {
                                    return(<option value={elm.name} key={index}>{elm.name}</option>)
                                })}
                            </select>
                            {CustomError && SelectCity === "" && <CustomMendotoryMsg value={SelectCity} label={"City"} />}
                        </div>
                    </div>
                </div>
                <div className="rowcustom rowcustom-col-2">
                    <div className={CustomError && apt_ste_bldg === "" ? "form-group error" : "form-group"}>
                        <label>apt,ste, bldg (optional)</label>
                        <div className="form-groupfiled">
                            <input type="text" value={apt_ste_bldg} onChange={(e) => setApt_ste_bldg(e.target.value)} className="form-control" placeholder="Enter apt,ste, bldg (optional)"/>
                            {CustomError && apt_ste_bldg === "" && <CustomMendotoryMsg value={apt_ste_bldg} label={"apt,ste, bldg"} />}
                        </div>
                    </div>
                    <div className={CustomError && Zip === "" ? "form-group error" : "form-group"}>
                        <label>Zip</label>
                        <div className="form-groupfiled">
                            <input type="number" className="form-control" value={Zip} onChange={(e) => setZip(e.target.value)} placeholder="Enter Zip"/>
                            {CustomError && Zip === "" && <CustomMendotoryMsg value={Zip} label={"Zip"} />}
                        </div>
                    </div>
                </div>
                <div className="rowcustom">
                    <div className="col-md-12">
                        <Button className="btn formcomnbtn" onClick={() => submitForm()}>Continue</Button>
                    </div>
                </div>
            </div>
        </div>)
}

export default PaymentCardDetails;