/* eslint-disable */
import React from "react";
import { Button } from "react-bootstrap";
import CorrectIcon from "../../assets/img/correct.svg";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { cardDetailsGet, customerDetailsGet } from "../../redux/actions";
import moment from "moment";

const SucessPayment = (props) => {
    const { setCurrentLocations } = props;
    const { cardDetails } = useSelector((state) => state.allReducers);
    const Navigate = useNavigate();
    const dispatch = useDispatch();
    const dateToday = new Date();

    const MakeAnotherPayment = () => {
        setTimeout(()=> {
            dispatch(cardDetailsGet(""));
            dispatch(customerDetailsGet(""));
            Navigate("/");
            setCurrentLocations("/");
        }, 100);
    };

    return(<div className="paymentWrappers">
        <div className="successpymentwrapper">
            <div className="succesiocn">
                <img src={CorrectIcon} alt="correct icon" />
            </div>
            <h4>Thank you for the payment!</h4>
            <p>You just paid Nationwide Software Solution</p>
            <div className="sucesspaymendeta">
                <div className="succpmnt">
                    <span>Y4IEYUX</span>
                    Payment Id
                </div>
                <div className="succpmnt">
                    <span>{moment.utc(dateToday).local().format('MMM D, YYYY')}</span>
                    Date
                </div>
            </div>
            <div className="totalamount">
                <h3>${cardDetails?.PayAmount}</h3>
                Amount Paid
            </div>
            <div className="successpymentbutns">
                <Button className="btn cancelbtn" onClick={() => MakeAnotherPayment()}>Make Another Payment</Button>
                <Button className="savebtn">Save Payment Details</Button>
            </div>
        </div>
    </div>)
}

export default SucessPayment;