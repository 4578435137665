import React from "react";
import Logo from "../../assets/img/logo.svg";
import { Image } from "react-bootstrap";

const Header = (props) => {
    const { logotitle } = props;

    return(<div className="paymentHeader">
        <div className="logowraps">
            <Image src={Logo} className="logo" alt="logo" />
        </div>
        <h4>{logotitle}</h4>
    </div>)
}

export default Header;