/*eslint-disable */
import React, { useState } from "react";
import Countries from "./CountryCodes.json";
import CaretDown from "../../../assets/img/caret-down.svg";

const CountryMobileInput = (props) => {
    const { setMobileNo, MobileNo } = props;
    const [ FlagsCountry, setFlagsCountry ] = useState(false);
    const [ SelectFlag, setSelectFlag ] = useState("US");
    const FlagsUrl = "https://purecatamphetamine.github.io/country-flag-icons/3x2/";

    // select country code & image
    const SelectCountryCode = (flags) => {
        setSelectFlag(flags.country_code);
        setMobileNo({...MobileNo, countrycode: flags.phone_code})
        setFlagsCountry(false);
    }

    // open cuntry dropdwon function
    const OpenCountryDrop = (e) => {
        e.preventDefault();
        setFlagsCountry(!FlagsCountry)
    }

    return (<div className="selectNoInputField">
        <div className="inputBoxSelect">
            <button className="selectEmojis" onClick={(e) => OpenCountryDrop(e)}>
                {SelectFlag !== "" && <img src={FlagsUrl + SelectFlag + ".svg"} className="flagsimg" alt="call image" />}
                <div className="countselet">+{MobileNo.countrycode}</div>
                <img src={CaretDown} className="arrowdownimg" alt="caret down" />
            </button>
            {FlagsCountry && (<ul className="flagsDropdown">
                {Countries.map((flags, index) => {
                    return(<li key={index} onClick={() => SelectCountryCode(flags)}>
                        <img src={FlagsUrl + flags.country_code + ".svg"} />
                        <span>{flags?.country_en} (+{flags.phone_code})</span>
                    </li>)
                })}
            </ul>)}
        </div>
        <input 
            type="number" 
            className="inputBoxs" 
            value={MobileNo.number}
            placeholder="Enter Phone Number"
            onChange={(e) => setMobileNo({...MobileNo, number: e.target.value})}
        />
    </div>)
}

export default CountryMobileInput;