export const ActionTypes = {
    CUSTOMER_DETAILS: "CUSTOMER_DETAILS",
    CARD_DETAILS: "CARD_DETAILS"
}

export const customerDetailsGet = (customer) => {
    return {
        type: ActionTypes.CUSTOMER_DETAILS,
        payload: customer,
    }
}

export const cardDetailsGet = (card) => {
    return {
        type: ActionTypes.CARD_DETAILS,
        payload: card,
    }
}

