/* eslint-disable */
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import "./assets/css/style.css";
import Header from './components/Payment/Header';
import PaymentCardDetails from './components/Payment/PaymentCardDetails';
import ConfirmPyment from './components/Payment/ConfirmPyment';
import SucessPayment from './components/Payment/SucessPayment';
import CustomerDetails from './components/Payment';
import TabHeader from './components/Payment/TabHeader';
import PymentInvoice from './components/Payment/PymentInvoice';
import Footer from './components/Payment/Footer';
import { useState } from 'react';

function App() {
  const [ currentLocations, setCurrentLocations ] = useState();

  return (
    <div className="wrapper">
        <div className="paymentwrapper">
            <div className="paymentformswrapps">
                <div className='container'>
                    <div className='row'>
                        <div className={(currentLocations === undefined || currentLocations === "/" || currentLocations === "/card-details" || currentLocations === "/confirm-payment") ? "col-7" : "col-12"} style={{ paddingRight: '60px' }}>
                            <BrowserRouter>
                              {(currentLocations === undefined || currentLocations === "/" || currentLocations === "/card-details" || currentLocations === "/confirm-payment")  && <Header logotitle={"Pay To Nationwide Software Solution"} />}
                              {(currentLocations === undefined || currentLocations === "/" || currentLocations === "/card-details" || currentLocations === "/confirm-payment")  && <TabHeader setCurrentLocations={setCurrentLocations} />}
                              <Routes>
                                <Route path="/" element={<CustomerDetails />} />
                                <Route path="/card-details" element={<PaymentCardDetails />} />
                                <Route path="/confirm-payment" element={<ConfirmPyment />} />
                                <Route path="/success" element={<SucessPayment setCurrentLocations={setCurrentLocations} />} />
                              </Routes>
                            </BrowserRouter>
                        </div>
                        {(currentLocations === undefined || currentLocations === "/" || currentLocations === "/card-details" || currentLocations === "/confirm-payment")  && <div className="col-5">
                            <PymentInvoice />
                        </div>}
                        <div className='col-12'>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default App;
