/* eslint-disable */
import { ActionTypes } from "../../redux/actions";

const initailData = {
    customerDetail : "",
    cardDetails: "",
}

export const paymentReducers = (state = initailData, action) => {
    switch(action.type) {
        case ActionTypes.CUSTOMER_DETAILS :
            return { 
                ...state, 
                customerDetail : action.payload, 
            };
        case ActionTypes.CARD_DETAILS :
            return { 
                ...state, 
                cardDetails : action.payload, 
            };
            default:
            return state;
    }
}