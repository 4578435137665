import React, { useState } from "react";
import CountrySelectInput from "../Common/CountrySelectInput/CountryMobileInput";
import { useNavigate } from "react-router-dom";
import CustomMendotoryMsg from "../Common/CustomMendotoryMsg";
import { Button } from "react-bootstrap";
import SucessIcon from "../../assets/img/success.svg";
import { customerDetailsGet } from "../../redux/actions";
import { useDispatch } from "react-redux";

const Payment = () => {
    const Navigate = useNavigate();
    const dispatch = useDispatch();
    const [ MobileNo, setMobileNo ] = useState({
        countrycode: "1",
        number: "",
    });
    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [businessName, setBusinessName] = useState("");
    const [InvoiceNo, setInvoiceNo ] = useState("");
    const [Memo, setMemo ] = useState("");
    // mendotory error state
    const [CustomError, setCustomError] = useState(false);

    // form submit
    const submitForm = () => {
        if(fname !== "" && lname !== "" && businessName !== "" && InvoiceNo !== "" && Memo !== "") {
            setCustomError(false);
            const payload = {
                FirstName: fname,
                LastName: lname,
                BusinessName: businessName,
                PhoneNumber: MobileNo,
                InvoiceNumber: InvoiceNo,
                Memo : Memo,
            };
            setTimeout(()=> {
                dispatch(customerDetailsGet(payload));
                Navigate("/card-details");
            }, 100);
        } else {
            setCustomError(true);
        };
    }

    return(<div className="paymentWrappers">
        <div className="paymentheadermain">
            <h4>Customer Detail :</h4>
        </div>
        <div className="paymentforms">
            <div className="rowcustom rowcustom-col-2">
                <div className={CustomError && fname === "" ? "form-group error" : "form-group"}>
                    <label>First Name</label>
                    <div className="form-groupfiled">
                        <input type="text" className="form-control" value={fname} onChange={(e) => setFname(e.target.value)} placeholder="Enter First Name" />
                        {fname !== "" && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                        {CustomError && fname === "" && <CustomMendotoryMsg value={fname} label={"First Name"} />}
                    </div>
                </div>
                <div className={CustomError && lname === "" ? "form-group error" : "form-group"}>
                    <label>Last Name</label>
                    <div className="form-groupfiled">
                        <input type="text" className="form-control" value={lname} onChange={(e) => setLname(e.target.value)} placeholder="Enter Last Name" />
                        {lname !== "" && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                        {CustomError && lname === "" && <CustomMendotoryMsg value={lname} label={"Last Name"} />}
                    </div>
                </div>
            </div>
            <div className="rowcustom">
                <div className="col-md-12">
                    <div className={CustomError && businessName === "" ? "form-group error" : "form-group"}>
                        <label>Business Name</label>
                        <div className="form-groupfiled">
                            <input type="text" className="form-control" value={businessName} onChange={(e) => setBusinessName(e.target.value)} placeholder="Enter Business Name" />
                            {businessName !== "" && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                            {CustomError && businessName === "" && <CustomMendotoryMsg value={businessName} label={"Business Name"} />}
                        </div>
                    </div>
                </div>
            </div>
            <div className="rowcustom">
                <div className="col-md-12">
                    <div className={CustomError && MobileNo.number === "" ? "form-group error" : "form-group"}>
                        <label>Phone Number</label>
                        <div className="form-groupfiled">
                            <CountrySelectInput 
                                MobileNo={MobileNo}
                                setMobileNo={setMobileNo}
                            />
                            {MobileNo.number !== "" && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                            {CustomError && MobileNo.number === "" && <CustomMendotoryMsg value={MobileNo.number} label={"Phone Number"} />}
                        </div>
                    </div>
                </div>
            </div>
            <div className="rowcustom">
                <div className="col-md-12">
                    <div className={CustomError && InvoiceNo === "" ? "form-group error" : "form-group"}>
                        <label>Invoice Number</label>
                        <div className="form-groupfiled">
                            <input type="number" className="form-control" value={InvoiceNo} onChange={(e) => setInvoiceNo(e.target.value)} placeholder="Enter invoice number" />
                            {InvoiceNo !== "" && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                            {CustomError && InvoiceNo === "" && <CustomMendotoryMsg value={InvoiceNo} label={"Invoice Number"} />}
                        </div>
                    </div>
                </div>
            </div>
            <div className="rowcustom">
                <div className="col-md-12">
                    <div className={CustomError && Memo === "" ? "form-group error" : "form-group"}>
                        <label>Memo (Optional)</label>
                        <div className="form-groupfiled">
                            <input type="text" className="form-control" value={Memo} onChange={(e) => setMemo(e.target.value)} placeholder="Enter memo (Reinstatement Fee, Apply to next Invoice, etc.)" />
                            {Memo !== "" && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                            {CustomError && Memo === "" && <CustomMendotoryMsg value={Memo} label={"Memo"} />}
                        </div>
                    </div>
                </div>
            </div>
            <div className="rowcustom">
                <div className="col-md-12">
                    <Button className="btn formcomnbtn" onClick={() => submitForm()}>Continue</Button>
                </div>
            </div>
        </div>
    </div>) 
}

export default Payment;