import React from "react";
import { useSelector } from "react-redux";

const PymentInvoice = () => {
    const { cardDetails } = useSelector((state) => state.allReducers);
    const Ammounts = cardDetails?.PayAmount / 2;

    return(<div className="invoicpreviewcol">
        <div className="invoicpreviewcolheader">
            <div className="invoiceid">
                <span>Invoice #</span>
                <div>INV2270</div>
            </div>
            <h4>Bill To</h4>
            <p>Aristocratic Supplies 700 Hwy D Osage Beach MO 65065 United States</p>
        </div>
        <div className="invoicpreviewpopupbody">
            <h5>Description</h5>
            <ul>
                <li>
                    <div className="Descriphead">
                        <h4>Ballentines</h4> 
                        <div>${isNaN(Ammounts) ? '0.0' : Ammounts}</div>
                    </div>
                    <div className="discrcolswraps">
                        <div className="discrcols">
                            <span>Intake</span>
                            5888.0
                        </div>
                        <div className="discrcols">
                            <span>Redeem</span>
                            5888.0
                        </div>
                        <div className="discrcols">
                            <span>Hold</span>
                            5888.0
                        </div>
                        <div className="discrcols">
                            <span>License Fee</span>
                            35%
                        </div>
                    </div>
                </li>
                <li>
                    <div className="Descriphead">
                        <h4>Fish Table</h4> 
                        <div>${isNaN(Ammounts) ? '0.0' : Ammounts}</div>
                    </div>
                    <div className="discrcolswraps">
                        <div className="discrcols">
                            <span>Intake</span>
                            482.0
                        </div>
                        <div className="discrcols">
                            <span>Redeem</span>
                            1302.0
                        </div>
                        <div className="discrcols">
                            <span>Hold</span>
                            820.0
                        </div>
                        <div className="discrcols">
                            <span>License Fee</span>
                            35%
                        </div>
                    </div>
                </li>
                <li>
                    <div className="Descriphead">
                        <h4>Credit</h4> 
                        <div>$0.0</div>
                    </div>
                    <div className="discrcolswraps">
                        <div className="discrcols">
                            <span>Intake</span>
                            -
                        </div>
                        <div className="discrcols">
                            <span>Redeem</span>
                            -
                        </div>
                        <div className="discrcols">
                            <span>Hold</span>
                            -
                        </div>
                        <div className="discrcols">
                            <span>License Fee</span>
                            -
                        </div>
                    </div>
                </li>
            </ul>
            <div className="totalinvoce">
                <h4>Total Amount :</h4>
                <h3>${cardDetails?.PayAmount === undefined ? '000' : cardDetails?.PayAmount}</h3>
            </div>
        </div>
    </div>)
}

export default PymentInvoice;