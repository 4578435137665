/* eslint-disable */
import React from "react";
import Alert from 'react-bootstrap/Alert';
import { Link } from "react-router-dom";
import MasterCardIcon from "../../assets/img/mastercard.png";
import { useSelector } from "react-redux";
import moment from "moment";

const ConfirmPyment = () => {
    const { customerDetail , cardDetails } = useSelector((state) => state.allReducers);
    const dateToday = new Date();

    return(<div className="paymentWrappers">
        <div className="paymentheadermain">
            <h4>Review Your Payment :</h4>
        </div>
        <div className="reviewpaymentforms">
            <ul className="paymentinvoics">
                <li>
                    <label>Payment Amount</label>
                    ${cardDetails.PayAmount}
                </li>
                <li>
                    <label>Fees</label>
                    $0.0
                </li>
                <li>
                    <label>Total</label>
                    ${cardDetails.PayAmount}
                </li>
            </ul>
            <div className="datwisepaymentmthds">
                <span>{moment.utc(dateToday).local().format('MMM D, YYYY')}</span>
                <h5>Nationwide software solution will receiving your payment</h5>
            </div>
            <div className="datwisepaymentmthds">
                <span>Paying with your</span>
                <h5><img src={MasterCardIcon} className="mastercardsIcon"/> Mastercard</h5>
            </div>
            <Alert className="alertmessage" variant="secondary">Your payment will be processed {moment.utc(dateToday).local().format('MMM D, YYYY')}</Alert>
        </div>
        <div className="reviewpaymentforms">
            <div className="datwisepaymentmthds">
                <span>Contact Name</span>
                <h5>{customerDetail.FirstName} {customerDetail.LastName}</h5>
            </div>
            <div className="datwisepaymentmthds">
                <span>Business Name</span>
                <h5>{customerDetail.BusinessName}</h5>
            </div>
            <div className="datwisepaymentmthds">
                <span>Account On invoice Number</span>
                <h5>{customerDetail.InvoiceNumber}</h5>
            </div>
            <div className="datwisepaymentmthds">
                <span>Memo (Optional)</span>
                <h5>{customerDetail.Memo}</h5>
            </div>
        </div>
        <div className="rowcustom">
            <div className="col-md-12">
                <Link to={'/success'} className="btn formcomnbtn" >Submit Payment</Link>
            </div>
        </div>
    </div>)
}

export default ConfirmPyment;