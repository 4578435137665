/* eslint-disable */
import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const TabHeader = (props) => {
    const { setCurrentLocations } = props;
    const location = useLocation();
    
    useEffect(() => {
        setCurrentLocations(location?.pathname)
    }, [ location ]);
    
    return(<div className="tabsHeaders">
        <ul>
            <li><div className={location.pathname === "/" || location.pathname === "/card-details" || location.pathname === "/confirm-payment" ? "link-secondary active" : "link-secondary"} ><span>1</span>Customer Details</div></li>
            <li><div className={location.pathname === "/card-details" || location.pathname === "/confirm-payment" ? "link-secondary active" : "link-secondary"} ><span>2</span>Payment Details</div></li>
            <li><div className={location.pathname === "/confirm-payment" ? "link-secondary active" : "link-secondary"} ><span>3</span>Confirmation</div></li>
        </ul>
    </div>)
}

export default TabHeader;